$fa-font-path: '/fonts';
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';

.fa-long-arrow-alt-right {
    margin: 0 8px;
    color: $thomann-grey;
}

.b-tooltip.is-primary:after {
    background: $thomann-blue;
}

.b-tooltip.is-top.is-primary:before {
    border-top-color: $thomann-blue;
}

.arrow {
    height: 2.5rem;
}
