.modal {
    .animation-content {
        padding: 3rem 5rem;
        max-width: 90vw;
        max-height: 90vh;
        background-color: $body-bg;
        overflow-y: auto;

        & > section {
            padding-bottom: 0;
        }
    }
}

.modal-card-head, .modal-card-foot {
    background: transparent;
    border: none;
}
