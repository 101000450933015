.color-thomann-blue {
    color: $thomann-blue;
}

.cursor-pointer {
    cursor: pointer;
}

.is-fullwidth {
    width: 100% !important;
}

/*****************
 *  Display
 */
.is-flex {
    display: flex !important;
}
.is-grid {
    display: grid;
}
.gap-2 {
    grid-gap: 2rem;
}
.items-center {
    align-items: center;
}
.justify-between {
    justify-content: space-between;
}
.is-flex-col {
    flex-direction: column;
}
.align-self-center {
    align-self: center;
}

/*****************
 *  Margin / Paddings
 */
.has-margin-none {
    margin: 0 !important;
}
.has-margin-top-md {
    margin-top: 2rem !important;
}
.has-margin-bottom-xs {
    margin-bottom: 0.5rem !important;
}
.has-margin-bottom-sm {
    margin-bottom: 1rem !important;
}
.has-margin-bottom-md {
    margin-bottom: 2rem !important;
}
.has-margin-bottom-lg {
    margin-bottom: 4rem !important;
}
.has-margin-bottom-xl {
    margin-bottom: 8rem !important;
}
.has-margin-right-sm {
    margin-right: 1rem !important;
}
.has-margin-right-md {
    margin-right: 2rem !important;
}
.has-margin-right-lg {
    margin-right: 4rem !important;
}
.has-margin-right-xl {
    margin-right: 8rem !important;
}

.has-padding-none {
    padding: 0 !important;
}
.has-padding-sm {
    padding: 1rem !important;
}
.has-padding-md {
    padding: 2rem !important;
}
.has-padding-lg {
    padding: 4rem !important;
}
.has-padding-xl {
    padding: 8rem !important;
}
.has-padding-top-sm {
    padding-top: 1rem !important;
}
.has-padding-top-md {
    padding-top: 2rem !important;
}
.has-padding-top-lg {
    padding-top: 4rem !important;
}
.has-padding-top-xl {
    padding-top: 8rem !important;
}
.has-padding-bottom-sm {
    padding-bottom: 1rem !important;
}
.has-padding-bottom-md {
    padding-bottom: 2rem !important;
}
.has-padding-bottom-lg {
    padding-bottom: 4rem !important;
}
.has-padding-bottom-xl {
    padding-bottom: 8rem !important;
}
.has-padding-left-sm {
    padding-left: 1rem !important;
}
.has-padding-left-md {
    padding-left: 2rem !important;
}
.has-padding-left-lg {
    padding-left: 4rem !important;
}
.has-padding-left-xl {
    padding-left: 8rem !important;
}
.has-padding-right-sm {
    padding-right: 1rem !important;
}
.has-padding-right-md {
    padding-right: 2rem !important;
}
.has-padding-right-lg {
    padding-right: 4rem !important;
}
.has-padding-right-xl {
    padding-right: 8rem !important;
}
