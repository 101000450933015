@font-face {
    font-family: 'Nunito', sans-serif;
    font-display: swap;
    font-weight: 400;
    src: url('/fonts/Nunito-Regular.ttf');
}

@font-face {
    font-family: 'Nunito', sans-serif;
    font-display: swap;
    font-weight: 700;
    src: url('/fonts/Nunito-Bold.ttf');
}

small.is-light {
    color: #898989;
}
