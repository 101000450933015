.button {
    background-image: linear-gradient(rgb(243, 243, 243), rgb(232, 232, 232));
    font-size: .9em;
    padding: calc(.27em - 1px) 1em;
    &.is-danger {
        background-image: unset;

        span {
            color: #fff;
            i {
                color: inherit;
            }
        }
        &.is-outlined span {
            color: #ff3860;
        }

        &:hover span, &:focus span {
            color: whitesmoke;
        }
        &.is-outlined:disabled:hover span{
            color: #ff3860;
        }
    }
    &.is-primary,
    &.is-primary[disabled] {
        background-image: unset;
        background-color: $thomann-blue;
        &:hover {
            background-color: $thomann-blue;
        }

        span, i {
            color: whitesmoke;
        }
    }
    &.is-success {
        background-image: unset;
        span, i {
            color: whitesmoke;
        }
    }
}
