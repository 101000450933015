table.table {
    th {
        border-color: $thomann-blue;
    }
    tr td {
        border-color: $thomann-grey;
        padding: 1em .75em;
    }
}

.table--empty {
    text-align: center;
    p {
        color: #666;
        font-size: 24px;
    }
}
