nav.navbar {
    width: 100%;
    display: grid;
    grid-template-columns: 150px 1fr 1fr 50px;
    background-color: transparent;
    h1 {
        grid-column: 1;
        margin: 14px 0;
        a {
            color: #363636;
        }
    }
    .navbar-item {
        &.has-dropdown {
            position: relative;
            grid-column: 4;
            align-items: center;
            justify-self: end;
            width: 100%;

            & > i {
                font-size: 36px;
                margin: auto;
            }
            .navbar-dropdown {
                position: absolute;
                left: -150px;
                width: 220px;
                padding: 1.5rem;
                border-radius: unset;
                border:none;

                a {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    grid-gap: 0.5rem;
                    align-items: center;

                    &:not(:last-of-type) {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
        &.search {
            grid-column: 3;
        }
    }
}

.navbar-brand {
    &__logo {
        height: 70px;
        padding-top: 0.5rem;
        margin-right: 1rem;
    }
}
