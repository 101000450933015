.decorativeBar {
    clear: both;
    display: table;
    background-color: rgb(242,245,246);
    width: 100vw;
    position: absolute;
    height: 300px;

    &--top {
        transform: translate(45%, -75%);
        -webkit-border-radius: 0 0 0 100px / 0 0 0 100px;
        -moz-border-radius: 0 0 0 100px / 0 0 0 100px;
        border-radius: 0 0 0 100px / 0 0 0 100px;
    }
    &--bottom {
        -webkit-border-radius: 0 100px 0 0 / 0 100px 0 0;
        -moz-border-radius: 0 100px 0 0 / 0 100px 0 0;
        border-radius: 0 100px 0 0 / 0 100px 0 0;
        right: 15px;
    }
    &--wrapper {
        position: absolute;
        overflow-y: hidden;
        height: 50px;
        margin-top: auto;
        right: 50%;
        bottom: 0;
        width: 100%;
    }
}
