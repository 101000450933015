.contractControl {
    h3 {
        margin-bottom: 0;
        &:first-of-type {
            margin-top: 0;
        }
    }
    &__attachments {
        margin-bottom: 2rem;
    }
    &__buttons {
        display: grid;
        grid-template-columns: 180px;
        button {
            margin-bottom: 1rem;
        }
    }
    &__datepicker {
        width: 180px;
    }
}
