.loginForm, .passwordResetRequestForm, .passwordResetForm {
    max-width: 650px;
    width: 90vw;
    padding: 4rem 5rem 4rem 3rem;
    margin: auto;
}

.loginForm {
    h2 {
        text-transform: uppercase;
    }
    &__remember {
        margin: 2rem 0;
    }
    &__login {
        margin-top: 2rem;
    }
}
