.methodUserList {
    &__head {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 200px 200px 200px auto;
    }

    &__row {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 200px 200px 200px auto;
    }

    &__data {
        grid-column: 1 / span 3;

        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 200px 200px 200px;
    }
}

.methodLocationList {
    &__head {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 300px 300px auto;
    }

    &__row {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 300px 300px auto;
    }

    &__data {
        grid-column: 1 / span 2;

        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 300px 300px;

        .select, select {
            width: 100%;
        }
    }
}
