.collapse {
    border: none;
    .card-header, .card, &.card {
        box-shadow: none;
    }
    .card-header {
        border-bottom: 1px solid $thomann-grey;
        &.collapse-open {
            border-bottom: 1px solid $thomann-blue;
        }
    }
    .card-content {
        padding: 0 0.75rem 0 3rem;
        .content {
            display: grid;
            grid-template-columns: 1fr 75px;
            align-items: center;
            padding: .75rem 0;
            margin-bottom: 0;
            border-bottom: 1px solid $thomann-grey;
            p {
                margin-bottom: 0;
            }
        }
    }
}
