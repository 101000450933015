article, aside, h1, h2, h3, h4, h5, h6, hgroup, nav, section {
    display: block;
}

h1 { margin-block-start: 0.67em; margin-block-end: 0.67em; font-size: 2.00em; font-weight: bold; }
h2 { margin-block-start: 0.83em; margin-block-end: 0.83em; font-size: 1.50em; font-weight: bold; }
h3 { margin-block-start: 1.00em; margin-block-end: 1.00em; font-size: 1.17em; font-weight: bold; }
h4 { margin-block-start: 1.33em; margin-block-end: 1.33em; font-size: 1.00em; font-weight: bold; }
h5 { margin-block-start: 1.67em; margin-block-end: 1.67em; font-size: 0.83em; font-weight: bold; }
h6 { margin-block-start: 2.33em; margin-block-end: 2.33em; font-size: 0.67em; font-weight: bold; }

* {
    color: #333;
}

.animation-content {
    z-index: 2;
}

html {
    height: 100%;
    min-width: 320px;
    background-color: $body-bg;
}

body {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

body, #app {
    min-height: 100%;
    height: 100%;
}

main {
    height: calc(100% - 76px);
}

main > .container {
    height: 100%;
}
