.b-checkbox.checkbox input[type=checkbox]:checked + .check {
    background-color: $thomann-blue;
    border-color: $thomann-blue;
}
.b-checkbox.checkbox:hover input[type=checkbox]:not(:disabled) + .check {
    border-color: $thomann-blue;
}

.switch input[type=checkbox]:checked + .check {
    background-color: $thomann-blue;
}
.switch:hover input[type=checkbox]:checked + .check {
    background: rgba($thomann-blue, 0.9);
}

.date-remove {
    position: absolute;
    right: 0.5rem;
    top: 0.4rem;
    cursor: pointer;
}
